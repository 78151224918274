<template>
    <div class="editor-page">
        <loader v-if="item==null || loading" :show="item==null" :width="40" :height="40"></loader>
        <top-bar
                v-if="item!=null"
                :title="id=='new' ? 'Inserimento Webcam' : 'Modifica Webcam'"
                preview=""
                :action="`Salva`"
                :actionIsValid="isValid"
                :loading="loading || item==null"
                :hideLang="true"
                @lang="(l) => lang = l"
                @onAction="save"
        ></top-bar>

        <form class="form row" v-if="item!=null">
            <div class="col-md-9">
                <card title="Informazioni di base" :collapse="false">
                    <content>
                        <div class="form-group">
                            <label>Titolo</label>
                            <input
                                    type="text"
                                    required="true"
                                    class="input form-control"
                                    aria-required="true"
                                    aria-invalid="false"
                                    v-model="item.contents[lang].title"
                            />
                        </div><!-- titolo -->
                        <textbox
                                title="Slug"
                                v-model="item.contents[ lang ].slug"
                        />
                    </content>
                </card>

                <card title="Dettaglio" :collapse="false">
                    <div class="form-group">
                        <label>URL Webcam</label>
                        <input
                                type="text"
                                class="input form-control"
                                aria-required="false"
                                aria-invalid="false"
                                v-model="item.webcam.url"
                        />
                        <hr>
                        <label>Note</label>
                        <rich-editor
                                v-model="item.webcam.note"
                        />
                    </div>
                </card>

                <card title="Indirizzo" :collapse="false">
                    <address-box v-model="item.locations"></address-box>
                </card><!-- indirizzo -->
            </div>
            <div class="col-md-3"><!-- SIDEBAR DX -->
                <card title="Impostazioni">
                   <statuses-box ref="sbox"  :lang="lang" :entity="item.id" v-model="item.contents[ lang ].status" title=false></statuses-box><!-- stato -->
                    <author-box v-model="item.contents[lang].author"></author-box><!-- autore -->
                </card><!-- impostazioni -->

                <card title="Tipologia" :collapse="false">
                    <fieldset>
                        <category-box :mainCategory="7" v-model="item.category"></category-box>
                    </fieldset>
                </card><!-- tipologia -->
            </div>
        </form>
    </div>
</template>

<script>
    import axios from "axios";
    import RichEditor from "../../components/altrama/RichEditor";
    import CategoryBox from "../../components/altrama/CategoryBox";
    import AuthorBox from "../../components/altrama/AuthorBox";
    import StatusesBox from "../../components/altrama/StatusesBox";
    import DatePicker from "../../components/altrama/DatePicker";
    import TopBar from "../../components/altrama/TopBar";
    import AddressBox from "../../components/altrama/AddressBox";
    import Textbox from "../../components/altrama/form/Textbox";
    import ATextarea from "../../components/altrama/form/ATextarea";

    export default {
        components: {
            RichEditor,
            CategoryBox,
            AuthorBox,
            StatusesBox,
            DatePicker,
            TopBar,
            AddressBox,
            Textbox,
            ATextarea
        },
        props: ["id", "path"],
        data() {
            return {
                needSave: false,
                hasErrors: false,
                loading: true,
                item: null,
                lang: "it"
            };
        },
        computed: {
            isValid: function () {
              if (!this.item.canSave) {
                if (this.item.contents[this.lang].status == 1) {
                  return false
                } else {
                  return true
                }
              }
              return true
            },
            template: function () {
                const userId = Number(this.$api.user.current().id);
                return {
                    id: "new",
                    contents: this.$contentTemplate({
                        title: "",
                        slug: "",
                        author: userId,
                        status: 2,
                        pub_date: this.$moment().format("YYYY-MM-DD HH:mm:ss")
                    }),
                    locations: {},
                    webcam: {
                        url: "",
                        note: ""
                    },
                    category: {},
                    canSave: false,
                    created_at: "",
                };
            }
        },
        methods: {
            save() {
                    let self = this;
                    let url = `${self.$url}webcam/${self.id}`;
                    let method = "put";

                    if (this.item.id == "new") {
                        method = 'post';
                        url = `${self.$url}webcam`;
                    }

                    this.item.lang = this.lang;
                    this.loading = true;

                    axios({
                        method: method,
                        url: url,
                        headers: this.$config.axiosHeaders(),
                        data: this.item
                    })
                        .then(function (response) {
                            self.loading = false;
                            self.$notifyVue(
                                "top",
                                "center",
                                response.data.status,
                                response.data.data.message,
                                "ti-check"
                            );
self.$refs.sbox.update();
                            if (response.data.data.id !== self.item.id) {
                                self.item.id = response.data.data.id;
                                self.$router.push({params: {id: self.item.id}})
                            }
                        })
                        .catch(function (error) {
                            self.loading = false;
                            self.hasErrors = true;
                            self.$processError(error);
                            
                        });
                    this.needSave = false;
            },
            loadData: function () {
                const self = this;
                self.item = null;

                if (this.item && this.id == this.item.id) {
                    console.log("Ricaricamento della stessa pagina");
                    return;
                }

                if (this.id == "new") {
                    self.item = Object.assign({}, self.template);
                    self.needSave = false;
                    self.loading = false;
                    return;
                }

                self.loading = true;
                axios({
                    method: "get",
                    url: self.$url + self.path + "/" + self.id,
                    headers: this.$config.axiosHeaders(),
                }).then(function (response) {
                    self.item = _.merge(self.template,response.data.data);                   
                    self.loading = false;
                    self.needSave = false;
                })
                    .catch(function (error) {
                        self.loading = false;
                        self.hasErrors = true;
                        self.$processError(error);
                        
                });

            }
        },
        mounted: function () {
            this.loadData();
        },
        watch: {
            item: {
                handler(val) {
                    this.needSave = true;
                },
                deep: true
            },
            id: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData();
                }
            }
        },
    };
</script>

<template>
    <div class="editor-page iat">
        <loader v-if="item==null || loading" :show="item==null" :width="40" :height="40"></loader>
        <top-bar
                v-if="item!=null"
                :title="id=='new' ? 'Inserimento Luogo' : 'Modifica Luogo'"
                :preview="''"
                :action="`Salva`"
                :actionIsValid="isValid"
                :loading="loading || item==null"
                :hideLang="true"
                @lang="(l) => { lang = l; $v.$touch(); }"
                @onAction="save"
        ></top-bar>
        <form :class="['form', 'row']" novalidate v-if="item!=null" @submit.prevent="submit">
            <div class="col-md-9">
                <card title="Informazioni di base" :collapse="false">
                    <content>
                        <div class="form-group">
                            <textbox
                                    title="Nome"
                                    :v="$v.item.nome"
                                    v-model="item.nome"
                            />
                        </div><!-- titolo -->

                         <div class="form-group">
                            <a-textarea
                                    title="Informazioni"
                                    :v="$v.item.info"
                                    v-model="item.info"
                                    :maxLength="250"
                            />
                        </div><!-- informazioni -->


                        <hr/>
                        <card title="Indirizzo" :collapse="false">
                             <div class="form-row">


                            <div class="form-group col-md-12">
                                <label>Destinazioni</label>
                                <destination-combo
                                        required="true"
                                        aria-required="true"
                                        aria-invalid="false"
                                        v-model="item.destination"
                                />
                            </div>
                        </div>
                            <address-box v-model="item.location"></address-box>
                        </card><!-- indirizzo -->
                        <hr/>
                        <card title="Contatti" :collapse="false">
                               <div class="row no-gutters">
                                    <div class="my-2 col-md-12 col-sm-12">
                                        <div class="input-group my-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope mr-2"></i></span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Inserisci Email" v-model="item.mail"  />
                                        </div>
                                    </div>
                                </div>

                                <div class="row no-gutters">
                                    <div class="my-2 col-md-12 col-sm-12">
                                        <div class="input-group my-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone mr-2"></i></span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Inserisci telefono" v-model="item.telefono"  />
                                        </div>
                                    </div>
                                </div>

                                <div class="row no-gutters">
                                    <div class="my-2 col-md-12 col-sm-12">
                                        <div class="input-group my-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-link mr-2"></i></span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Inserisci Sito Web" v-model="item.website"  />
                                        </div>
                                    </div>
                                </div>
                        </card>
                    </content>
                </card>
            </div>
            <div class="col-md-3"><!-- SIDEBAR DX -->
                <card title="Impostazioni">
                  <!-- <statuses-box ref="sbox"  v-model="item.status" title=false></statuses-box> stato -->
                    <author-box v-model="item.author"></author-box><!-- autore -->

                    <!-- comune -->
                </card><!-- impostazioni -->
            </div>
        </form>
    </div>
</template>

<script>
    import axios from "axios";

    import Textbox from "../../components/altrama/form/Textbox";
    import ATextarea from "../../components/altrama/form/ATextarea";

    import RichEditor from "../../components/altrama/RichEditor";
    import TagManager from "../../components/altrama/TagManager";
    import CategoryCombo from "../../components/altrama/CategoryCombo";
    import ContactsEditor from "../../components/altrama/ContactsEditor";

    import AuthorBox from "../../components/altrama/AuthorBox";
    import StatusesBox from "../../components/altrama/StatusesBox";
    import DatePicker from "../../components/altrama/DatePicker";
    import AdvancedDatePicker from "../../components/altrama/AdvancedDatePicker";
    import AdvancedTimePicker from "../../components/altrama/AdvancedTimePicker";
    import TicketBox from "../../components/altrama/TicketBox";
    import TappeBox from "../../components/altrama/TappeBox";
    import AttachmentsBox from "../../components/altrama/AttachmentsBox";
    import TopBar from "../../components/altrama/TopBar";
    import AddressBox from "../../components/altrama/AddressBox";
    import CityCombo from "../../components/altrama/CityCombo";
    import CityComboMultiselect from "../../components/altrama/CityComboMultiselect";
    import DestinationCombo from "../../components/altrama/DestinationCombo";

    import {validationMixin} from "vuelidate";
    import {required} from "vuelidate/lib/validators";

    export default {
        components: {
            RichEditor,
            TagManager,
            CategoryCombo,
            ContactsEditor,
            AuthorBox,
            StatusesBox,
            DatePicker,
            AdvancedDatePicker,
            AdvancedTimePicker,
            AttachmentsBox,
            TappeBox,
            TicketBox,
            TopBar,
            Textbox,
            ATextarea,
            AddressBox,
            CityCombo,
            CityComboMultiselect,
            DestinationCombo
        },
        props: ["id","code"],
        mixins: [validationMixin],
        validations() {
            if (
                !!this.item
            ) {
                return {
                    item: {
                        contents: this.$contentTemplate({
                            title: {required},
                        })
                    }
                };
            }
            return {
                item: {
                    contents: this.$contentTemplate({
                        title: {required},
                        locations: {required},
                    })
                }
            };
        },
        data() {
            return {
                needSave: false,
                hasErrors: false,
                loading: true,
                item: null,
                lang: "it"
            };
        },
        computed: {
            isValid: function () {

              return true
            },
            template: function () {
                const userId = Number(this.$api.user.current().id);
                return {
                    id: "new",
                    status:1,
                    author:userId,
                    location: {},
                    nome:"",
                    info:"",
                    destination: {
                        id: "",
                        name: ""
                    },
                    telefono:'',
                    mail:'',
                    website:'',
                    comune: {},
                    canSave: true,
                    created_at: "",
                    updated_at: "",
                    code:this.code
                };
            }
        },
        methods: {
            save: _.debounce(function () {
                this.$v.$touch();
                if (true) {


                    let self = this;
                    let url = `${self.$url}luoghi/${self.item.id}`;
                    let method = "put";

                    if (this.item.id == "new") {
                        method = 'post';
                        url = `${self.$url}luoghi`;
                    }

                    this.item.lang = this.lang;
                    this.loading = true;
                    axios({
                        method: method,
                        url: url,
                        headers: this.$config.axiosHeaders(),
                        data: this.item
                    })
                        .then(function (response) {
                            self.loading = false;
                            self.$notifyVue(
                                "top",
                                "center",
                                response.data.status,
                                response.data.data.message,
                                "ti-check"
                            );
                            self.$refs.sbox.update();
                            if (response.data.data.id !== self.item.id) {
                                self.item.id = response.data.data.id;
                                self.$router.push({params: {id: self.item.id,code:self.item.code}})
                            }

                        })
                        .catch(function (error) {
                            self.loading = false;
                            self.hasErrors = true;
                            self.$processError(error);

                        });

                    this.needSave = false;
                }
            }, 300),
            loadData: function () {
                let self = this;
                self.item = null;

                if(this.item && this.id == this.item.id){
                    console.log("Ricaricamento della stessa pagina");
                    return;
                }

                if (this.id == "new") {
                    self.item = JSON.parse(JSON.stringify(self.template));
                    self.needSave = false;
                    self.loading = false;
                    return;
                }

                self.loading = true;
                axios({
                    method: "get",
                    url: `${self.$url}luoghi/${self.id}`,
                    headers: this.$config.axiosHeaders()
                })
                    .then(function(response) {
                        self.item = _.merge(JSON.parse(JSON.stringify(self.template)), response.data.data);
                        self.loading = false;
                        self.needSave = false;
                    })
                    .catch(function(error) {
                        self.loading = false;
                        self.hasErrors = true;
                        self.$processError(error);

                    });
            }
        },
        mounted: function() {
            this.loadData();
        },
        watch: {

             id: {
              handler(val, oldVal) {
                    if(oldVal!=val && val=="new")
                        location.reload();
              },
              deep: true
            },

            item: {
                handler(val, oldVal) {
                    this.needSave = true;
                },
                deep: true
            },
            '$route': 'loadData'
        },
    };
</script>

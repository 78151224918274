<template>
  <div v-if="user !== 'redazione-destinazione-turistica'">
    <!--Stats cards-->
    <div class="row">
      <div
        class="col-sm-6 col-md-4 col-lg-3"
        v-for="stats in this.item.summary"
        :key="stats.tipo"
      >
        <stats-card>
          <div
            class="icon-big text-center"
            :class="`icon-${ getColor(stats.tipo) }`"
            slot="header"
          >
            <i :class="getIcon(stats.tipo)"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{ getLabel(stats.tipo)}}</p>
            {{ stats.conto }}
          </div>
        </stats-card>
      </div>
    </div>

    <div class="row">
      <card
        class="col-12 col-xl-6 events"
        title="Contenuti da validare"
        :collapse="true"
      >
      <eventi :data="item.events_draft" :langs="langs" />
      </card>
<card
        class="col-12 col-xl-6 events"
        title="Contenuti da revisionare"
        :collapse="true"
      >
         <eventi :data="item.events" :langs="langs" />
      </card>






      <card
        class="col-12 summary"
        title="Produzione contenuti"
        :collapse="true"
      >
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th
                rowspan="3"
                class="right-border"
                style="vertical-align: bottom"
              >
                Redazione
              </th>
              <th
                class="right-border text-center"
                v-for="n in Object.values(nome)"
                :key="n"
                colspan="4"
              >
                {{ n }}
              </th>
            </tr>
            <tr>
              <template v-for="n in Object.values(nome)">
                <th
                  class="year text-center"
                  colspan="2"
                  v-for="y in years"
                  :key="n + y"
                >
                  {{ y }}
                </th>
              </template>
            </tr>
            <tr>
              <template v-for="n in Object.values(nome)">
                <template v-for="y in years">
                  <th
                    class="lang text-center"
                    v-for="l in langs"
                    :key="n + y + l"
                  >
                    {{ l }}
                  </th>
                </template>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr class="cells" v-for="(r, i) in item.contents" :key="i">
              <td class="text-left">{{ r.redazione }}</td>
              <template v-for="n in Object.keys(nome)">
                <template v-for="y in years">
                  <template v-if="n !== 'media'">
                    <td v-for="l in langs" :key="n + y + l">
                      {{
                        r.anni[y] && r.anni[y][l] && r.anni[y][l][n]
                          ? r.anni[y][l][n]
                          : 0
                      }}
                    </td>
                  </template>
                  <template v-else>
                    <td colspan="2" :key="n + y + 'it'">
                      {{
                        r.anni[y] && r.anni[y]["it"] && r.anni[y]["it"][n]
                          ? r.anni[y]["it"][n]
                          : 0
                      }}
                    </td>
                  </template>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
      </card>
    </div>
    <!--
    <div
      class="row"
    >
      <div class="col-12">
        <redazioni :data="item.contents.redazioni"></redazioni>
      </div>
    </div>-->
  </div>
  <div v-else>
    <div style="text-align: center; margin-top: 400px">
      <img :src="$branding.logoPositivo" alt="Logo" />
      <h1>Dashboard</h1>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.summary {
  td {
    vertical-align: middle !important;
    text-align: center;
    border: 1px solid #dcdcdc;
  }
  .cells td:nth-child(even) {
    background-color: rgb(247, 247, 247);
  }

  td:nth-child(2n + 1),
  .year:nth-child(2n),
  .lang:nth-child(2n),
  .right-border {
    border-right: 1px solid #a0a0a0;
  }

  .cells:hover {
    td {
      background-color: #d3d3d3;
    }
  }

  td:not(:first-child),
  th:not(:first-child) {
    width: 30px;
  }

  .cells {
    font-family: monospace;
    font-size: 1rem;
  }

  thead th {
    background-color: #fff;
  }
}

.events {
  table {
    tr.it {
      border-top: 3px solid #7e7e7e;
    }

    tr.en {
      border-bottom: 3px solid #7e7e7e;
    }

    td {
      padding: 15px !important;
    }
  }
}
</style>

<script>
import axios from "axios";
import { StatsCard } from "@/components/index";
import Redazioni from "@/components/Dashboard/Redazioni";
import Eventi from "@/components/Dashboard/Eventi";
import Card from "../components/Cards/Card.vue";

export default {
  components: {
    StatsCard,
    Redazioni,
    Eventi,
    Card,
    Eventi,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      loading: {
        summary: true,
      },
      item: {
        summary: {},
        events: [],
        events_draft: [],
        redazioni: [],
        overview: {},
      },
      nome: {
        destination: "Destinazioni",
        itinerary: "Itinerari",
        event: "Eventi",
        iat: "IAT",
        download: "Download",
        media: "Foto",
      },
      years: [],
      langs: this.$available_langs_code,
      user: "",
      icons: {
        media: {
          icon: "ti-image",
          color: "primary",
          label: "Media",
        },
        event: {
          icon: "ti-calendar",
          color: "warning",
          label: "Eventi",
        },
        destination: {
          icon: "ti-world",
          color: "danger",
          label: "Destinazioni",
        },
        iat: {
          icon: "ti-info-alt",
          color: "primary",
          label: "Iat",
        },
        itinerary: {
          icon: "ti-direction-alt",
          color: "warning",
          label: "Itinerari",
        },
        interessi: {
          icon: "ti-eye",
          color: "danger",
          label: "Interessi",
        },
        download: {
          icon: "ti-import",
          color: "primary",
          label: "Download",
        },
        page: {
          icon: "ti-layers-alt",
          color: "warning",
          label: "Pagine",
        },
        users: {
          icon: "ti-user",
          color: "danger",
          label: "Utenti",
        },
        news: {
          icon: "ti-files",
          color: "primary",
          label: "News",
        },
        webcam: {
          icon: "ti-camera",
          color: "warning",
          label: "Webcam",
        },
        poi: {
          icon: "ti-location-pin",
          color: "danger",
          label: "POI",
        },
      }
    };
  },
  methods: {

    getIcon: function(icon){
      if( this.icons[icon] ){
        return this.icons[icon].icon;
      }else{
        return "ti-location-pin";
      }
    },
    getColor: function(icon){
      if( this.icons[icon] ){
        return this.icons[icon].color;
      }else{
        return 'primary';
      }
    },
    getLabel: function(icon){
      if( this.icons[icon] ){
        return this.icons[icon].label;
      }else{
        return icon;
      }
    },
    loadData: function () {
      let self = this;
      self.loading.summary = true;

      axios({
        method: "get",
        url: `${self.$url}dashboard`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.item, "contents", response.data);
          self.loading.summary = false;
        })
        .catch(function (error) {
          self.loading.summary = false;
          self.$processError(error);
        });
    },
    loadSummary: function () {
      this.years = [new Date().getFullYear() - 1, new Date().getFullYear()];
      let self = this;
      self.loading.summary = false;
      axios({
        method: "get",
        url: `${self.$url}dashboard/summary`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.item, "summary", response.data);
          self.loading.summary = false;
        })
        .catch(function (error) {
          self.loading.summary = false;
          self.$processError(error);
        });
    },
    loadEvents: function (stato, name) {
      let self = this;
      self.loading.summary = false;
      axios({
        method: "get",
        url: `${self.$url}dashboard/events?stato=${stato}`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.item, name, response.data.data);
          self.loading.summary = false;
        })
        .catch(function (error) {
          self.loading.summary = false;
          self.$processError(error);
        });
    },
  },
  mounted: async function () {
    this.user = (await this.$api.user.current()).role;
    this.loadSummary();
    this.loadData();
    this.loadEvents(4,"events");
    this.loadEvents(3,"events_draft");
  },
};
</script>

import Vue from 'vue'

import VeeValidate, {
  Validator
} from 'vee-validate'
var VueValidationSwe2 = require('vee-validate/dist/locale/it');

Vue.use(VeeValidate, {
  locale: 'it',
  events: 'change|blur',
  dictionary: VueValidationSwe2
});
Validator.localize("it", VueValidationSwe2);

import Loader from './components/altrama/Loader';
Vue.component('loader', Loader);

import CountryFlag from 'vue-country-flag'
Vue.component('vue-country-flag', CountryFlag);


import FlagAvailableContentLanguage from "./components/Inputs/FlagAvailableContentLanguage.vue";
Vue.component('flag-available-content-language', FlagAvailableContentLanguage);

import datePicker from 'vue-bootstrap-datetimepicker';
Vue.use(datePicker);


const api = require("./api").default;
Vue.prototype.$api = api;

Vue.prototype.$url = process.env.VUE_APP_API_URL;
Vue.prototype.$urlBase = process.env.VUE_APP_API_BASEPATH;
Vue.prototype.$showSidebar = false;

Vue.prototype.$config = {
  getLanguage() {
    return 'it';
  },
  axiosHeaders() {
    return {
      'authorization': 'Bearer ' + api.getToken(),
      'accept-language': this.getLanguage(),
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json; charset=UTF-8'
    }
  },
  axiosHeadersUpload() {
    return {
      'authorization': 'Bearer ' + api.getToken(),
      'accept-language': this.getLanguage(),
      "Access-Control-Allow-Origin": "*",
      "enctype": "multipart/form-data"
    }
  }
};

import { branding, routes } from "../app";

Vue.prototype.$branding = branding;

import App from './App.vue'
import VueRouter from "vue-router";
Vue.use(VueRouter);

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active"
});

export default router;

import { it } from "./languages/italian.js";
import { en } from "./languages/english.js";

const messages = {
  it,
  en,
};

import VueI18n from 'vue-i18n'
import PaperDashboard from "./plugins/paperDashboard";
import VueSidebarMenu from 'vue-sidebar-menu'

Vue.use(VueSidebarMenu);
Vue.use(PaperDashboard);
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'it',
  messages,
  fallbackLocale: 'it',
});


import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
  moment,
});

Vue.prototype.$contentTemplate = function (data, lang) {

  if (typeof lang === 'undefined') {
    return {
      "it": typeof data === 'object' ? JSON.parse(JSON.stringify(data)) : "",
      "en": typeof data === 'object' ? JSON.parse(JSON.stringify(data)) : "",
      //    "de": typeof data === 'object' ? JSON.parse( JSON.stringify(data)) : ""
    };
  } else {
    let obj = {};
    obj[lang] = typeof data === 'object' ? JSON.parse(JSON.stringify(data)) : "";
    return obj;
  }
}

Vue.prototype.$available_langs = [{
  code: "it",
  label: "Italiano"
}, {
  code: "en",
  label: "Inglese"
}/*,
{
  code: "de",
  label: "Tedesco"
}*/
]

Vue.prototype.$available_langs_code = Vue.prototype.$available_langs.map((l) => l.code);


import 'pretty-checkbox/src/pretty-checkbox.scss';
import PrettyInput from 'pretty-checkbox-vue/input';
import PrettyCheck from 'pretty-checkbox-vue/check';
import PrettyRadio from 'pretty-checkbox-vue/radio';

Vue.component('p-input', PrettyInput);
Vue.component('p-check', PrettyCheck);
Vue.component('p-radio', PrettyRadio);

import VCalendar from 'v-calendar';
Vue.use(VCalendar, { locale: "it" });

import VueGoodTable from 'vue-good-table';
Vue.use(VueGoodTable);

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

Vue.component("vue-dropzone", vue2Dropzone);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

var jQuery = require("jquery");
window.jQuery = jQuery;
window.$ = jQuery;

const VERSION = process.env.VUE_APP_VERSION;

if (localStorage.getItem("version") !== VERSION) {
  localStorage.clear();
  localStorage.setItem("version", VERSION);
}

console.info("Versione " + VERSION);
const bootstrap = require("bootstrap");


Vue.prototype.$getArraySynch = function (arrayToSynch) {
  /*var keys = [];
  for (let obj of arrayToSynch) {
    keys.push(parseInt(obj.id));
  }*/
  return arrayToSynch.map((obj) => parseInt(obj.id));
};


//TODO : Rimuovere
Vue.prototype.$processError = function (error) {
  if (error.response) {
    // Request made and server responded
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    console.log(error.response.data.errors);

    var textMessage = "";
    if (error.response.data.errors) {
      for (const [key, value] of Object.entries(error.response.data.errors)) {

        if (key !== 'debug') {
          if (Array.isArray(value)) {
            textMessage += value.join("<br>");
          } else {
            textMessage += value;
          }
          textMessage += "<br>";
        }
      }
    }


    if (error.response.data.message) {
      textMessage += error.response.data.message;
    }

    if (error.response.data.data && error.response.data.data.message) {
      textMessage += error.response.data.data.message;
    }



    Vue.notify({
      type: 'error',
      text: textMessage,
      duration: 5000,
      speed: 500
    });

    if (error.response.status === 403) {
      localStorage.clear();
      location.replace(process.env.VUE_APP_API_BASEPATH + "/#/login");
    }

  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
}



/** @deprecated Non usare più **/
Vue.prototype.$getNavigationTable = function () {
  let navigationTableData = localStorage.getItem('navigation_table') || "null";
  return JSON.parse(navigationTableData);
};


Vue.prototype.$getNavigationInfo = function (path) {
  let data = JSON.parse(localStorage.getItem("navigation" + path) || 'null');

  if (data) {
    return data.split("_");
  }
  return null;
};

Vue.prototype.$notifyVue = function (verticalAlign, horizontalAlign, color, message_response, icon_response) {
  this.$notify({
    text: message_response + "",
    position: verticalAlign + " " + horizontalAlign,
    type: color
  });
};

String.prototype.slugify = function (separator = "-") {
  return this
    .toString()
    .normalize('NFD')                   // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);
};



router.beforeEach(async (to, from, next) => {
    console.info("opening:" + to.name);
    try {
      const isAuthenticated = await api.user.current() !== null;
      if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
      else next();
    } catch (error) {
      next({ name: 'login' });
    }
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');

/* Css */
import "./assets/css/themify-icons.css";
import 'leaflet/dist/leaflet.css';
import 'bootstrap/scss/bootstrap.scss'
import "./assets/sass/paper-dashboard.scss";

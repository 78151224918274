<template>
<div>
  <card :title="label">
      <form
        @submit.prevent="validateBeforeSubmit"
        novalidate
        data-vv-scope="form-1"
        onkeypress="return event.keyCode != 13;"
      >
          <div class="row">
            <div class="col-md-6">
              <label>Nome</label>
              <input
                type="text"
                v-validate="validate_rag_soc"
                :class="{ 'input form-control': true }"
                name="Nome"
                placeholder=""
                v-model="entity.first_name"
              />
              <p class="text-danger" v-if="errors.has('form-1.Nome')">
                {{ errors.first("form-1.Nome") }}
              </p>
            </div>
            <div class="col-md-6">
              <label>Cognome</label>
              <input
                type="text"
                v-validate="validate_rag_soc"
                :class="{ 'input form-control': true }"
                name="Cognome"
                placeholder=""
                v-model="entity.last_name"
              />
              <p class="text-danger" v-if="errors.has('form-1.Cognome')">
                {{ errors.first("form-1.Cognome") }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Username</label>
              <input
                type="text"
                v-validate="'required|alpha_dash'"
                :class="{ 'input form-control': true }"
                name="Username Utente"
                placeholder=""
                v-model="entity.username"
              />
              <p
                class="text-danger"
                v-if="errors.has('form-1.Username Utente')"
              >
                {{ errors.first("form-1.Username Utente") }}
              </p>
            </div>
            <div class="col-md-6">
              <label>Email</label>
              <input
                type="email"
                v-validate="'required|email'"
                :class="{ 'input form-control': true }"
                name="Email Utente"
                placeholder=""
                v-model="entity.email"
              />
              <p class="text-danger" v-if="errors.has('form-1.Email Utente')">
                {{ errors.first("form-1.Email Utente") }}
              </p>
            </div>
          </div>

          <div class="row" v-if="id == 'new'">
            <div class="col-md-6">
              <label>Password</label>
              <input
                name="password utente"
                v-validate="'required|min:8'"
                ref="password"
                :class="{ 'input form-control': true }"
                type="password"
                placeholder="***********"
                v-model="entity.password"
              />
              <p
                class="text-danger"
                v-if="errors.has('form-1.password utente')"
              >
                {{ errors.first("form-1.password utente") }}
              </p>
            </div>
            <div class="col-md-6">
              <label>Conferma Password</label>
              <input
                v-validate="'required|confirmed:password'"
                :class="{ 'input form-control': true }"
                name="conferma password utente"
                type="password"
                placeholder="***********"
                v-model="entity.password_confirmation"
              />
              <p
                class="text-danger"
                v-if="errors.has('form-1.conferma password utente')"
              >
                {{ errors.first("form-1.conferma password utente") }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Ruolo</label>
                <select
                  v-model="user.role"
                  v-validate="'required'"
                  name="ruolo"
                  class="custom-select"
                >
                  <option
                    v-for="item in roles"
                    :value="item.name"
                    :key="item.id"
                    :selected="item.name == user.role"
                  >
                    {{ item.title }}
                  </option>
                </select>
                <p class="text-danger" v-if="errors.has('form-1.ruolo')">
                  {{ errors.first("form-1.ruolo") }}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Stato Account</label>
                <select
                  v-model="user.active"
                  v-validate="'required'"
                  name="Stato Account"
                  class="custom-select"
                >
                  <option
                    v-for="item in user.attivazione"
                    :value="item.value"
                    :key="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
                <p
                  class="text-danger"
                  v-if="errors.has('form-1.Stato Account')"
                >
                  {{ errors.first("form-1.Stato Account") }}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <related-redazione
                type="user"
                :option_selected_input="redazioneInput"
                v-on:updateRedazione="updateRedazione($event)"
              >
              </related-redazione>
            </div>
          </div>
        <!--role end -->
        <div class="text-center">
          <a class="btn btn-primary" href="#/users" >
            CHIUDI
          </a>
          <button class="btn btn-success" :disabled="disabled_submit">
            Salva
          </button>
          <div class="clearfix"></div>
        </div>
      </form>
   </card>
      <card
        title="Aggiornamento Password Utente"
        v-if="id !== 'new'"
      >
        <form
          @submit.prevent="validateUserPasswordForm"
          novalidate
          data-vv-scope="form-2"
        >
          <!-- AREA DEDICATA IN CASO DI MODIFICA USER ALLA SOLA PASSWORD-->
          <div class="row">
            <div class="col-md-4">
              <input
                name="password_update"
                v-validate="'required|min:8'"
                ref="password_update"
                :class="{ 'input form-control': true }"
                type="password"
                placeholder="Password"
                v-model="entity.password_update"
              />
              <p
                class="text-danger"
                v-if="errors.has('form-2.password_update')"
              >
                {{ errors.first("form-2.password_update") }}
              </p>
            </div>
            <div class="col-md-4">
              <input
                v-validate="'required|confirmed:password_update'"
                :class="{ 'input form-control': true }"
                name="conferma_password_update"
                type="password"
                placeholder="Conferma Password"
                v-model="entity.password_confirmation_update"
              />
              <p
                class="text-danger"
                v-if="errors.has('form-2.conferma_password_update')"
              >
                {{ errors.first("form-2.conferma_password_update") }}
              </p>
            </div>
            <div class="col-md-2">
              <label
                ><input type="checkbox" name="send" v-model="user.send" /> Invia
                Email</label
              >
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary">Modifica Password</button>
            </div>
          </div>
        </form>
      </card>


    <v-dialog
      :adaptive="true"
      :max-width="1000"
      :max-height="400"
      :clickToClose="false"
      :scrollable="true"
      :draggable="false"
      :resizable="false"
      width="60%"
      height="auto"
    />
  </div>
</template>

<script>
import axios from "axios";
import RelatedRedazione from "@/components/altrama/RelatedRedazione";
import Vue from "vue";
import VModal from "vue-js-modal";

Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });

export default {
  components: {
    RelatedRedazione,
  },
  props: ["id", "path"],
  watch: {
    id: function (newVal, oldVal) {
      location.reload();
    },
  },
  data() {
    return {
      workingObjectContents: {
        languages:this.$available_langs_code,
      },
      entity: {
      },
      user: {
        role: "",
        active: "",
        send: "",
        attivazione: [
          {
            value: 0,
            label: "Non Attivo",
          },
          {
            value: 1,
            label: "Attivo",
          },
        ],
        selected_destination_user: [],
        destination_user: [],
        isLoading_user_destination: false,
      },

      roles: {},
      role: {
        columns: [
          {
            label: "",
            field: "index",
            hidden: true,
          },
          {
            label: "Entità",
            field: "entity_name",
            tdClass: "text-left text-capitalize",
            thClass: "text-left",
          },
          {
            label: "Visualizza",
            field: "view",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Crea",
            field: "create",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Modifica",
            field: "edit",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Cancella",
            field: "delete",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Pubblica",
            field: "publish",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
        ],
        rows: [],
        permission: [],
        abilities: [],
      },

      value: [],
      disabled_submit: false,
      label: "Update Data",
      myVarTimeout: "", //variabile per interrompere processo di timeout nelle richieste al server per ottenere le potenziali destinazioni degli utenti
      redazioneInput: -1,
      redazioneOutput: -1,
    };
  },

  computed: {
    validate_rag_soc() {
      if (this.entity.company == null || this.entity.company.length == 0)
        return "required|alpha_spaces";

      return "";
    },
  },

  provide() {
    return { parentValidator: this.$validator };
  },

  methods: {
    updateRedazione: function (idRedazione) {
      this.redazioneOutput = idRedazione;
    },
    updatePlaces(places) {
      this.entity.related_places = places.map( (obj) => parseInt(obj.id) );
    },

    getLanguageName: function (key) {
      var obj = this.$t("languages_string");
      if (obj[key] != null)
        return obj[key].replace(/\b\w/g, (l) => l.toUpperCase());
      return "";
    },

    getNameCheckBox: function (name, operation) {
      return name + "-" + operation;
    },

    validateBeforeSubmit() {
      this.$validator.validateAll("form-1").then((result) => {
        if (result) {
          this.process_request();
          return;
        }
      });
    },

    process_request() {
      if (this.id == "new") this.new_record();
      else this.update();
    },
    validateUserPasswordForm() {
      this.$validator.validateAll("form-2").then((result) => {
        if (result) {
          this.changeUserPassword();
          return;
        }
      });
    },

    changeUserPassword() {
      var self = this;
      this.id = this.$route.params.id;
      this.path = this.$route.params.path;

      axios({
        method: "post",
        url: self.$url + self.path + "/update_password_utente",
        headers: this.$config.axiosHeaders(),
        params: {
          id: self.id,
          send: self.user.send,
          password: self.entity.password_update,
        },
      })
        .then(function (response) {
          self.$notifyVue(
            "top",
            "center",
            response.data.status,
            response.data.data.message,
            "ti-check"
          );
        })
        .catch(function (error) {
          for (var key in error.response.data.errors) {
            if (key != "debug")
              self.$notifyVue(
                "top",
                "center",
                "warning",
                error.response.data.errors[key][0],
                "ti-close"
              );
          }
          console.log(error);
          self.$processError(error);
        });
    },

    new_record() {
      var self = this;
      self.disabled_submit = true;

      axios({
        method: "post",
        url: this.$url + "user",
        headers: this.$config.axiosHeaders(),
        data: self.getData(),
      })
        .then(function (response) {

          var icon = "ti-check";

          if (response.data.code != "201") {

            icon = "ti-close";
          }
          self.$notifyVue(
            "top",
            "center",
            response.data.status,
            response.data.data.message,
            icon
          );
        })
        .catch(function (error) {
          console.error(error.response);
          self.disabled_submit = false;
          self.$processError(error);
          for (var key in error.response.data.errors) {
            if (key != "debug")
              self.$notifyVue(
                "top",
                "center",
                "warning",
                error.response.data.errors[key][0],
                "ti-close"
              );
          }
        });
    },

    update() {
      var self = this;
      let id = this.$route.params.id;
      axios({
        method: "put",
        url: this.$url + self.path + "/" + id,
        headers: this.$config.axiosHeaders(),
        data: self.getData(),
      })
        .then(function (response) {
          self.$notifyVue(
            "top",
            "center",
            response.data.status,
            response.data.data.message,
            "ti-check"
          );
        })
        .catch(function (error) {
          console.error(error.response);
          self.$processError(error);
          for (var key in error.response.data.errors) {
            if (key != "debug")
              self.$notifyVue(
                "top",
                "center",
                "warning",
                error.response.data.errors[key],
                "ti-close"
              );
          }
        });
    },

    updateLabel(id) {
      var label = "";
      if (id == "new") {
        label = "Nuovo ";
      } else label = "Aggiornamento ";
      label += " Utente";
      return label;
    },

    getData() {
      return {
            company: this.entity.company,
            first_name: this.entity.first_name,
            last_name: this.entity.last_name,
            username: this.entity.username,
            password: this.entity.password,
            password_confirmation: this.entity.password_confirmation,
            email: this.entity.email,
            role: this.user.role,
            active: this.user.active,
            redazione: this.redazioneOutput,
            destination: this.$getArraySynch(
              this.user.selected_destination_user,
              "key"
            )
          };
    },
  },

  created: async function () {
    var self = this;
    this.label = this.updateLabel(
      this.$route.params.id
    );

    if (this.$route.params.id != "new") {
      axios({
        method: "get",
        url: self.$url + "user/" + self.id,
        headers: this.$config.axiosHeaders(),
        params: {
          id: this.id,
        },
      })
        .then(function (response) {
          self.entity = response.data.data;


            self.user.role = Object.keys(self.entity.roles)[0];
            self.user.active = self.entity.active;
            self.redazioneInput = self.entity.redazione;
            //inserisco le zone pre-caricate se presenti
            var obj = response.data.data.destinations;
            self.user.destination_user = [];
            for (var key in obj) {
              if (obj.hasOwnProperty(key)) {
                var item = { id: key, name: obj[key] };
                self.user.selected_destination_user.push(item);
              }
            }

        })
        .catch(function (error) {
          console.error(error);
          self.$processError(error);
        });
    }

      // recupero i ruoli
      self.roles = [];
      axios({
        method: "get",
        url: this.$url + "user/roles",
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          if (response.data.code == "200") {
            var obj = response.data.data;
            for (let role of obj) {
              self.roles.push({
                id: role.id,
                name: role.name,
                title: role.title,
              });
            }
          }
        })
        .catch(function (error) {
          self.$processError(error);
          console.error(error);
        });



  },

  mounted: async function () {
    let canCreateUser =
      (await this.$api.user.can("user-create")) &&
      this.$route.params.id == "new";
    let canEditUser =
      (await this.$api.user.can("user-edit")) && this.id != "new";

    if (!(canCreateUser || canEditUser)) {
      this.$router.replace("/not-found");
    }
  },
};
</script>

<style>
.custom_tag {
  display: inline-block;
  padding: 3px 12px;
  background: #b7bce3;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.custom_remove {
  padding: 0;
  font-size: 10px;
  margin-left: 5px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{attrs:{"title":_vm.label}},[_c('div',[_c('form',{attrs:{"novalidate":"","data-vv-scope":"form-1","onkeypress":"return event.keyCode != 13;"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit.apply(null, arguments)}}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('label',[_vm._v("Titolo")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.entity.title),expression:"entity.title"}],class:{ 'input form-control': true },attrs:{"type":"text","name":"titolo","placeholder":"Titolo"},domProps:{"value":(_vm.entity.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.entity, "title", $event.target.value)}}}),(_vm.errors.has('form-1.titolo'))?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("form-1.titolo"))+" ")]):_vm._e()])]),_c('card',{attrs:{"title":"Permessi"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field != 'entity_name')?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.abilities),expression:"abilities"}],attrs:{"type":"checkbox"},domProps:{"value":_vm.getNameCheckBox(
                          props.row.entity_name,
                          props.column.field
                        ),"checked":Array.isArray(_vm.abilities)?_vm._i(_vm.abilities,_vm.getNameCheckBox(
                          props.row.entity_name,
                          props.column.field
                        ))>-1:(_vm.abilities)},on:{"change":function($event){var $$a=_vm.abilities,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.getNameCheckBox(
                          props.row.entity_name,
                          props.column.field
                        ),$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.abilities=$$a.concat([$$v]))}else{$$i>-1&&(_vm.abilities=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.abilities=$$c}}}})]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])})],1)])])],1),_c('div',{staticClass:"text-center"},[_c('p-button',{attrs:{"type":"basic"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.back.apply(null, arguments)}}},[_vm._v(" CHIUDI ")]),_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.disabled_submit}},[_vm._v(" Salva ")]),_c('div',{staticClass:"clearfix"})],1)])]),_c('v-dialog',{attrs:{"adaptive":true,"max-width":1000,"max-height":400,"clickToClose":false,"scrollable":true,"draggable":false,"resizable":false,"width":"60%","height":"auto"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
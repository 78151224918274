<template>
    <div class="editor-page">
        <loader v-if="item==null || loading" :show="item==null" :width="40" :height="40"></loader>
        <top-bar
                v-if="item!=null"
                :title="id=='new' ? 'Inserimento Download' : 'Modifica Download'"
                :preview="''"
                :action="`Salva`"
                :actionIsValid="isValid"
                :loading="loading || item==null"
                @lang="(l) => lang = l"
                @onAction="save"
        ></top-bar>

        <form class="form row" v-if="item!=null">
            <div class="col-md-9">
                <card title="Informazioni di base" :collapse="false">
                    <content>
                        <div class="form-group">
                            <label>Titolo</label>
                            <input
                                    type="text"
                                    required="true"
                                    class="input form-control"
                                    aria-required="true"
                                    aria-invalid="false"
                                    v-model="item.contents[lang].title"
                            />
                        </div><!-- titolo -->
                        <hr/>
                        <div class="form-group">
                            <label>Descrizione</label>
                            <rich-editor
                                    aria-required="false"
                                    aria-invalid="false"
                                    v-model="item.contents[lang].descrizioneFile"
                            />
                        </div><!-- descrizione -->
                        <div class="form-group">
                            <label>Comuni</label>
                        <city-combo-multiselect v-model="item.comune" ></city-combo-multiselect>
                     </div>
                    </content>
                    <footer></footer>
                </card>
                <card title="Elenco File" :collapse="false">
                    <list-file-component messageFileAccepted="Sono ammessi solo files pdf."  v-model="item.attachments"></list-file-component>
                </card>
            </div>
            <div class="col-md-3"><!-- SIDEBAR DX -->
                <card title="Impostazioni">
                   <statuses-box ref="sbox"  :lang="lang" :entity="item.id" v-model="item.contents[ lang ].status" title=false></statuses-box><!-- stato -->
                    <author-box v-model="item.contents[lang].author"></author-box><!-- autore -->
                      <div class="form-group">
                    <div class="custom-control custom-checkbox form-check">
                      <input type="checkbox" id="ismappa" class="custom-control-input form-check-input" v-model="item.mappa" />
                      <label class="custom-control-label form-check-label" for="ismappa"> Mappa </label>
                    </div> </div>
                </card><!-- impostazioni -->

                <card title="Altro" :collapse="false">
                    <fieldset>
                        <h5>Destinazione</h5>
                        <category-combo :mainCategory="2" v-model="item.destinazione"></category-combo>
                    </fieldset><!-- destinazione -->
                    <br />
                    <fieldset>
                        <h5>Interessi</h5>
                        <category-combo :mainCategory="5" v-model="item.theme"></category-combo>
                    </fieldset><!-- destinazione -->

                </card><!-- altro -->
            </div>
        </form>
    </div>
</template>

<script>
    import axios from "axios";
    import RichEditor from "../../components/altrama/RichEditor";
    import TagManager from "../../components/altrama/TagManager";
    import CategoryCombo from "../../components/altrama/CategoryCombo";
    import AuthorBox from "../../components/altrama/AuthorBox";
    import StatusesBox from "../../components/altrama/StatusesBox";
    import DatePicker from "../../components/altrama/DatePicker";
    import AttachmentsBox from "../../components/altrama/AttachmentsBox";
    import TopBar from "../../components/altrama/TopBar";
    import CityComboMultiselect from "../../components/altrama/CityComboMultiselect";
    import ListFileComponent from "../../components/altrama/DownloadEntityComponent"

    export default {
        components: {
            RichEditor,
            TagManager,
            CategoryCombo,
            AuthorBox,
            StatusesBox,
            DatePicker,
            AttachmentsBox,
            TopBar,
            CityComboMultiselect,
            ListFileComponent
        },
        props: ["id", "path"],
        data() {
            return {
                needSave: false,
                hasErrors: false,
                loading: true,
                item: null,
                lang: "it"
            };
        },
        computed: {
            isValid: function () {
              if (!this.item.canSave) {
                if (this.item.contents[this.lang].status == 1) {
                  return false
                } else {
                  return true
                }
              }
              return true
            },
            template: function () {
                const currentUserId = Number(this.$api.user.current().id);
                return {
                    id: "new",
                    mappa: false,
                    contents: this.$contentTemplate({
                        title: "",
                        descrizioneFIle: "",
                        permalink: "",
                        author: currentUserId,
                        status: 2,
                    }),
                    attachments: [],
                    destinazione: [],
                    theme: [],
                    comune: [],
                    canSave: false,
                    created_at: "",
                    updated_at: "",
                };
            }
        },
        methods: {
            save() {
                let self = this;
                let url = `${self.$url}download/${self.id}`;
                let method = "put";

                if (this.item.id == "new") {
                    method = 'post';
                    url = `${self.$url}download`;
                }

                this.item.lang = this.lang;
                this.loading = true;

                axios({
                    method: method,
                    url: url,
                    headers: this.$config.axiosHeaders(),
                    data: this.item
                })
                    .then(function (response) {
                        self.loading = false;
                        self.$notifyVue(
                            "top",
                            "center",
                            response.data.status,
                            response.data.data.message,
                            "ti-check"
                        );
self.$refs.sbox.update();
                        if (response.data.data.id !== self.item.id) {
                            self.item.id = response.data.data.id;
                            self.$router.push({params: {id: self.item.id}})
                        }
                    })
                    .catch(function (error) {
                        self.loading = false;
                        self.hasErrors = true;
                        self.$processError(error);

                    });
                this.needSave = false;
            },
            loadData: function () {
                const self = this;
                self.item = null;

                if (this.item && this.id == this.item.id) {
                    console.log("Ricaricamento della stessa pagina");
                    return;
                }

                if (this.id == "new") {
                    self.item = Object.assign({}, self.template);
                    self.needSave = false;
                    self.loading = false;
                    return;
                }

                self.loading = true;

                axios({
                    method: "get",
                    url: self.$url + self.path + "/" + self.id,
                    headers: this.$config.axiosHeaders(),
                }).then(function (response) {
                    self.item = _.merge(self.template, response.data.data);
                    self.loading = false;
                    self.needSave = false;
                }).catch(function (error) {
                    self.loading = false;
                    self.hasErrors = true;
                    self.$processError(error);

                });
            }
        },
        mounted: function () {
            this.loadData();
        },
        watch: {
            item: {
                handler(val) {
                    this.needSave = true;
                },
                deep: true
            },
           id: {
                handler(val, oldVal) {
                    if(oldVal!=val && val=="new")
                        location.reload();
                },
                deep: true
            }
        },
    };
</script>

<template>
  <card :title="label">
    <div>
      <form
        @submit.prevent="validateBeforeSubmit"
        novalidate
        data-vv-scope="form-1"
        onkeypress="return event.keyCode != 13;"
      >
        <div>
          <div class="row">
            <div class="col-md-12">
              <label>Titolo</label>
              <input
                type="text"
                v-validate="'required'"
                :class="{ 'input form-control': true }"
                name="titolo"
                placeholder="Titolo"
                v-model="entity.title"
              />
              <p class="text-danger" v-if="errors.has('form-1.titolo')">
                {{ errors.first("form-1.titolo") }}
              </p>
            </div>
          </div>
          <card title="Permessi">
            <div class="row">
              <div class="col-md-12">
                <vue-good-table :columns="columns" :rows="rows">
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field != 'entity_name'">
                      <input
                        type="checkbox"
                        :value="
                          getNameCheckBox(
                            props.row.entity_name,
                            props.column.field
                          )
                        "
                        v-model="abilities"
                      />
                    </span>
                    <span v-else>{{
                      props.formattedRow[props.column.field]
                    }}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </card>
        </div>
        <!--role end -->
        <div class="text-center">
          <p-button type="basic" @click.native.prevent="back">
            CHIUDI
          </p-button>
          <button class="btn btn-success" :disabled="disabled_submit">
            Salva
          </button>
          <div class="clearfix"></div>
        </div>
      </form>

    </div>

    <v-dialog
      :adaptive="true"
      :max-width="1000"
      :max-height="400"
      :clickToClose="false"
      :scrollable="true"
      :draggable="false"
      :resizable="false"
      width="60%"
      height="auto"
    />
  </card>
</template>

<script>
import axios from "axios";
import RelatedRedazione from "@/components/altrama/RelatedRedazione";
import Vue from "vue";
import VModal from "vue-js-modal";

Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });

export default {
  components: {
    RelatedRedazione,
  },
  props: ["id", "path"],
  watch: {
    id: function (newVal, oldVal) {
      location.reload();
    },
  },
  data() {
    return {
      entity: {
      },

        columns: [
          {
            label: "",
            field: "index",
            hidden: true,
          },
          {
            label: "Entità",
            field: "entity_name",
            tdClass: "text-left text-capitalize",
            thClass: "text-left",
          },
          {
            label: "Visualizza",
            field: "view",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Crea",
            field: "create",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Modifica",
            field: "edit",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Cancella",
            field: "delete",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
          {
            label: "Pubblica",
            field: "publish",
            tdClass: "text-center",
            thClass: "text-center",
            html: true,
          },
        ],
        rows: [],
        permission: [],
        abilities: [],
      value: [],
      disabled_submit: false,
      label: "Update Data",

    };
  },

  provide() {
    return { parentValidator: this.$validator };
  },

  methods: {

    updatePlaces(places) {
      this.entity.related_places = places.map( (obj) => parseInt(obj.id) );
    },

    getLanguageName: function (key) {
      var obj = this.$t("languages_string");
      if (obj[key] != null)
        return obj[key].replace(/\b\w/g, (l) => l.toUpperCase());
      return "";
    },

    getNameCheckBox: function (name, operation) {
      return name + "-" + operation;
    },

    validateBeforeSubmit() {
      this.$validator.validateAll("form-1").then((result) => {
        if (result) {
         if (this.id == "new") this.new_record();
      else this.update();
        }
      });
    },
    new_record() {
      var self = this;
      this.id = this.$route.params.id;

      self.disabled_submit = true;

      axios({
        method: "post",
        url: this.$url + 'user/roles',
        headers: this.$config.axiosHeaders(),
        data: {
            title: this.entity.title,
            abilities: this.abilities,
          },
      })
        .then(function (response) {
          var color = 2;
          var icon = "ti-check";

          if (response.data.code != "201") {
            color = 3;
            icon = "ti-close";
          }
          self.$notifyVue(
            "top",
            "center",
            response.data.status,
            response.data.data.message,
            icon
          );
        })
        .catch(function (error) {
          self.disabled_submit = false;
          self.$processError(error);
        });
    },

    update() {
      var self = this;
      let id = this.$route.params.id;


      axios({
        method: "put",
        url: this.$url + "user/roles/" + id,
        headers: this.$config.axiosHeaders(),
        data: {
            title: this.entity.title,
            abilities: this.abilities,
          },
      })
        .then(function (response) {
          self.$notifyVue(
            "top",
            "center",
            response.data.status,
            response.data.data.message,
            "ti-check"
          );
        })
        .catch(function (error) {
          console.error(error.response);
          self.$processError(error);
          for (var key in error.response.data.errors) {
            if (key != "debug")
              self.$notifyVue(
                "top",
                "center",
                "warning",
                error.response.data.errors[key],
                "ti-close"
              );
          }
        });
    },

    updateLabel(id) {
      var label = "";
      if (id == "new") {
        label = "Nuovo ";
      } else label = "Aggiornamento ";

      label += " Ruoli";

      return label;
    }
  },
  created: function () {

    var self = this;
    this.label = this.updateLabel(
      this.$route.params.id,
      this.$route.params.path
    );

    if (this.$route.params.id != "new") {
      axios({
        method: "get",
        url: self.$url + "user/roles/" + self.id,
        headers: this.$config.axiosHeaders(),
        params: {
          id: this.id,
        },
      })
        .then(function (response) {
          self.entity = Object.assign( {}, response.data.data );
        })
        .catch(function (error) {
          console.error(error);
          self.$processError(error);
        });
    }


      self.row = [];
      var urlAbilities = this.$url + "user/roles/abilities";
      if (this.$route.params.id != "new") {
        urlAbilities = this.$url + "user/roles/" + this.id + "/abilities";
      }

      axios({
        method: "get",
        url: urlAbilities,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {

          if (response.data.code == "200") {
            var responseJson = response.data.data.permission;
            var arrayName = Object.keys(response.data.data.permission);
            var permissions = Object.values(response.data.data.permission);
            for (var i = 0; i < arrayName.length; i++) {
              var entity_name = arrayName[i];
              var view_value = 0;
              var create_value = 0;
              var edit_value = 0;
              var delete_value = 0;

            for (var permission of permissions) {
                var itemObjectName = Object.keys(permission);
                var itemObjectValues = Object.values(permission);
                var help = itemObjectName[0];
                var entity_current = help.split("_");
                if (entity_current[0] == entity_name) {
                  for (var z = 0; z < itemObjectValues.length; z++) {
                    var temp = itemObjectName[z];
                    var curr_op = temp.split("_");
                    if (curr_op[1] == "view") view_value = itemObjectValues[z];
                    if (curr_op[1] == "edit") edit_value = itemObjectValues[z];
                    if (curr_op[1] == "create")
                      create_value = itemObjectValues[z];
                    if (curr_op[1] == "delete")
                      delete_value = itemObjectValues[z];
                  }
                }
              }

              //aggiungo righe alla tabella contenente i permessi
              self.rows.push({
                index: i,
                entity_name: entity_name,
                view: view_value,
                create: create_value,
                edit: edit_value,
                delete: delete_value,
              });
              //RIVEDI E ANNULLA CICLO PRECEDENTE SE NECESSARIO
            }
            for (var key in responseJson) {
              if (responseJson.hasOwnProperty(key)) {
                var o2 = responseJson[key];
                for (var key2 in o2) {
                  if (o2.hasOwnProperty(key2) && o2[key2] == 1)
                    self.abilities.push(key2);
                }
              }
            }
          }
        })
        .catch(function (error) {
          self.$processError(error);
          console.error(error);
        });
      //abilities recuperate
    }
  ,

  mounted: async function () {
    let canCreateUser =
      (await this.$api.user.can("user-create")) &&
      this.$route.params.id == "new";
    let canEditUser =
      (await this.$api.user.can("user-edit")) && this.id != "new";

    if (!(canCreateUser || canEditUser)) {
      this.$router.replace("/not-found");
    }
  },
};
</script>

<style>
.custom_tag {
  display: inline-block;
  padding: 3px 12px;
  background: #b7bce3;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.custom_remove {
  padding: 0;
  font-size: 10px;
  margin-left: 5px;
}
</style>

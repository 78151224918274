<template>
  <div class="editor-page licenze">
    <loader
      v-if="item == null || loading"
      :show="item == null"
      :width="40"
      :height="40"
    ></loader>
    <top-bar
      v-if="item != null"
      :title="id == 'new' ? 'Inserimento licenza' : 'Modifica licenza'"
      :action="`Salva`"
      :actionIsValid="isValid"
      :loading="loading || item == null"
      @lang="
        (l) => {
          lang = l;
          $v.$touch();
        }
      "
      @onAction="save"
    ></top-bar>
    <div v-if="item && item.draft > 0">
      <div class="alert alert-info alert-with-icon">
        <button type="button" aria-hidden="true" class="close">×</button>
        <span data-notify="icon" class="ti-bell"></span>
        <span data-notify="message">Scheda BOZZA di Revisione</span>
      </div>
    </div>
    <form :class="['form', 'row']" novalidate v-if="item != null">
      <div class="col-md-9">
        <card title="Informazioni" :collapse="false">
          <content>
            <textbox
              title="Nome Licenza"
              :v="$v.item.contents[lang].title"
              v-model="item.contents[lang].title"
            />
            <textbox
              title="Url"
              :v="$v.item.contents[lang].url"
              v-model="item.contents[lang].url"
            />
            <div class="form-group">
              <h5>Descrizione</h5>
              <rich-editor
                :v="$v.item.contents[lang].description"
                v-model="item.contents[lang].description"
              />
            </div>
          </content>
        </card>
      </div>
      <div class="col-md-3">
        <card title="Impostazioni">
         <statuses-box ref="sbox"
            v-model="item.contents[lang].status"
            title="false"
            :lang="lang"
            :entity="item.id"
            :statuses="item.nextStatuses[lang]"
          ></statuses-box
          ><!-- stato -->
        </card>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import Textbox from "../../components/altrama/form/Textbox";
import ATextarea from "../../components/altrama/form/ATextarea";
import RichEditor from "../../components/altrama/RichEditor";
import TagManager from "../../components/altrama/TagManager";
import CategoryCombo from "../../components/altrama/CategoryCombo";
import ContactsEditor from "../../components/altrama/ContactsEditor";
import AuthorBox from "../../components/altrama/AuthorBox";
import StatusesBox from "../../components/altrama/StatusesBox";
import DatePicker from "../../components/altrama/DatePicker";
import AdvancedDatePicker from "../../components/altrama/AdvancedDatePicker";
import AdvancedTimePicker from "../../components/altrama/AdvancedTimePicker";
import TicketBox from "../../components/altrama/TicketBox";
import TappeBox from "../../components/altrama/TappeBox";
import AttachmentsBox from "../../components/altrama/AttachmentsBox";
import TopBar from "../../components/altrama/TopBar";
import PortalBox from "../../components/altrama/PortalBox";
import IATCombo from "../../components/altrama/IATCombo";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["id"],
  mixins: [validationMixin],
  validations() {
    if (
      !!this.item &&
      !!this.item.contents[this.lang] &&
      this.item.contents[this.lang].status == 1
    ) {
      return {
        item: {
          contents: this.$contentTemplate({
            title: { required },
            url: { required }
          }),
        },
      };
    }
    return {
      item: {
        contents: this.$contentTemplate({
          title: "",
          url: "",
        }),
      },
    };
  },
  data() {
    return {
      needSave: false,
      hasErrors: false,
      loading: true,
      item: null,
      lang: "it",
    };
  },
  computed: {
    isValid: function () {

      if (!this.item.canSave) {

        if (this.item.contents[this.lang].status == 1) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    template: function () {
      return {
        id: "new",
        contents: this.$contentTemplate({
          title: "",
          url: "",
          status: 2,
        }),
        nextStatuses: this.$contentTemplate(null),
        canSave: true,
        created_at: "",
        updated_at: "",
      };
    },
  },
  methods: {
    save: _.debounce(function () {
      this.$v.$touch();
      if (!this.$v.item.contents[this.lang].$anyError) {
        let self = this;
        let url = `${self.$url}licence/${self.item.id}`;
        let method = "put";

        if (this.item.id == "new") {
          method = "post";
          url = `${self.$url}licence`;
        }

        this.item.lang = this.lang;
        this.loading = true;

        axios({
          method: method,
          url: url,
          headers: this.$config.axiosHeaders(),
          data: this.item,
        })
          .then(function (response) {
            self.loading = false;
            self.$notifyVue(
              "top",
              "center",
              response.data.status,
              response.data.data.message,
              "ti-check"
            );
            self.$refs.sbox.update();
            if (response.data.data.id !== self.item.id) {
              self.item.id = response.data.data.id;
              self.$router.push({ params: { id: self.item.id } });
            }
            self.item.contents[self.lang].permalink =
              response.data.data.permalink + "?preview=1";
          })
          .catch(function (error) {
            self.loading = false;
            self.hasErrors = true;
            self.$processError(error);

          });

        this.needSave = false;
      }
    }, 300),
    loadData: function () {
      let self = this;
      self.item = null;

      if (this.item && this.id == this.item.id) {
        console.log("Ricaricamento della stessa pagina");
        return;
      }

      if (this.id == "new") {
        self.item = JSON.parse(JSON.stringify(self.template));
        self.needSave = false;
        self.loading = false;
        return;
      }

      self.loading = true;
      axios({
        method: "get",
        url: `${self.$url}licence/${self.id}`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.item = _.merge(
            JSON.parse(JSON.stringify(self.template)),
            response.data.data
          );
          self.loading = false;
          self.needSave = false;
        })
        .catch(function (error) {
          self.loading = false;
          self.hasErrors = true;
          self.$processError(error);

        });
    },
  },
 mounted: async function () {
    this.canAddTags = await this.$api.user.can('view-tag');
    this.loadData();
  },
  watch: {
    id: {
      handler(val, oldVal) {
        this.loadData();
      },
      deep: true,
    },
  },
  components: {
    RichEditor,
    TagManager,
    CategoryCombo,
    ContactsEditor,
    AuthorBox,
    StatusesBox,
    DatePicker,
    AdvancedDatePicker,
    AdvancedTimePicker,
    AttachmentsBox,
    TappeBox,
    TicketBox,
    TopBar,
    Textbox,
    ATextarea,
    PortalBox,
    IATCombo,
  },
};
</script>

<style>
.custom_tag {
  display: inline-block;
  padding: 3px 12px;
  background: #b7bce3;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.custom_remove {
  padding: 0;
  font-size: 10px;
  margin-left: 5px;
}
</style>

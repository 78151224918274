<template>
    <div class="editor-page idee">
        <loader v-if="item==null || loading" :show="item==null" :width="40" :height="40"></loader>
        <top-bar
                v-if="item!=null"
                :title="id=='new' ? 'Inserimento interesse' : 'Modifica interesse'"
                :preview="item.contents[lang].permalink + '?preview=1'"
                :action="`Salva`"
                :actionIsValid="isValid"
                :loading="loading || item==null"
                @lang="(l) => { lang = l; $v.$touch(); }"
                @onAction="save"
        ></top-bar>
      <div v-if="item && item.draft > 0">
        <div class="alert alert-info alert-with-icon">
          <button type="button" aria-hidden="true" class="close">×</button>
          <span data-notify="icon" class="ti-bell"></span>
          <span data-notify="message">Scheda BOZZA di Revisione</span>
        </div>
      </div>
        <form class="form row" v-if="item!=null">
            <div class="col-md-9">
                <card title="Informazioni di base" :collapse="false">
                    <content>
                        <textbox
                                title="Titolo"
                                :v="$v.item.contents[ lang ].title"
                                v-model="item.contents[ lang ].title"
                                @blur="item.contents[ lang ].slug = item.contents[ lang ].slug || (item.contents[ lang ].title.slugify())"
                        />
                        <textbox
                                title="Slug"
                                :v="$v.item.contents[ lang ].slug"
                                v-model="item.contents[ lang ].slug"
                        />
                        <textbox title="Sottotitolo" :maxLength="90" v-model="item.contents[ lang ].subtitle"/>

                        <div class="form-group">
                            <h5>Descrizione</h5>
                            <rich-editor
                                    :v="$v.item.contents[ lang ].description"
                                    v-model="item.contents[ lang ].description"
                            />
                        </div>

                        <fieldset>
                            <h5>SEO</h5>
                            <hr/>
                            <textbox
                                    title="Keywords"
                                    :v="$v.item.contents[ lang ].google.keywords"
                                    v-model="item.contents[ lang ].google.keywords"
                            />
                            <textbox
                                    title="Titolo Google"
                                    :v="$v.item.contents[ lang ].google.title"
                                    v-model="item.contents[ lang ].google.title"
                            />
                            <a-textarea
                                    title="Descrizione Google"
                                    :maxLength="160"
                                    :v="$v.item.contents[ lang ].google.description"
                                    v-model="item.contents[ lang ].google.description"
                            />
                        </fieldset>
                        <fieldset>
                            <h5>Social</h5>
                            <textbox
                                    title="Titolo Account Social"
                                    :v="$v.item.contents[ lang ].social.title"
                                    v-model="item.contents[ lang ].social.title"
                            />
                            <a-textarea
                                    title="Descrizione Account Social"
                                    :maxLength="160"
                                    :v="$v.item.contents[ lang ].social.description"
                                    v-model="item.contents[ lang ].social.description"
                            />
                        </fieldset>
                    </content>
                </card>

                <card title="Immagini" :collapse="false">
                    <attachments-box v-model="item.attachments"></attachments-box>
                </card>

            </div>
            <div class="col-md-3"><!-- SIDEBAR DX -->
                <card title="Impostazioni">
                   <statuses-box ref="sbox"  :lang="lang" :entity="item.id" v-model="item.contents[ lang ].status" title=false></statuses-box><!-- stato -->
                    <author-box v-model="item.contents[lang].author"></author-box><!-- autore -->
                </card><!-- impostazioni -->
                <card title="Altro">
                    <fieldset>
                        <h5>Comune</h5>
                        <city-combo-multiselect v-model="item.comune" city-name=""
                                                provincia=""></city-combo-multiselect>
                    </fieldset><!-- comune -->
                    <hr>
                    <fieldset>
                        <h5>Interessi</h5>
                        <category-combo :mainCategory="5" v-model="item.destinazione"></category-combo>
                    </fieldset><!-- destinazione -->
                </card><!-- Altro -->
                <card title="Temi">
                    <tematism-box v-model="item.theme"/>
                </card><!-- Interessi -->

            </div>
        </form>
    </div>
</template>

<script>
    import axios from "axios";
    import Textbox from "../../components/altrama/form/Textbox";
    import ATextarea from "../../components/altrama/form/ATextarea";

    import CityComboMultiselect from "../../components/altrama/CityComboMultiselect";
    import RichEditor from "../../components/altrama/RichEditor";
    import TagManager from "../../components/altrama/TagManager";
    import CategoryCombo from "../../components/altrama/CategoryCombo";
    import CategoryBox from "../../components/altrama/CategoryBox";
    import AuthorBox from "../../components/altrama/AuthorBox";
    import StatusesBox from "../../components/altrama/StatusesBox";
    import DatePicker from "../../components/altrama/DatePicker";
    import AttachmentsBox from "../../components/altrama/AttachmentsBox";
    import TopBar from "../../components/altrama/TopBar";
    import CityCombo from "../../components/altrama/CityCombo";
    import ContactsEditor from "../../components/altrama/ContactsEditor";
    import AddressBox from "../../components/altrama/AddressBox";
    import TematismBox from "../../components/altrama/section/interessi/TematismBox";
    import PortalBox from "../../components/altrama/PortalBox";

    import {validationMixin} from "vuelidate";
    import {required} from "vuelidate/lib/validators";

    export default {
        components: {
            Textbox,
            ATextarea,
            RichEditor,
            TagManager,
            CategoryCombo,
            CategoryBox,
            AuthorBox,
            StatusesBox,
            DatePicker,
            AttachmentsBox,
            TopBar,
            CityCombo,
            ContactsEditor,
            AddressBox,
            CityComboMultiselect,
            TematismBox,
            PortalBox

        },
        props: ["id", "path"],
        mixins: [validationMixin],
        validations() {
            if (
                !!this.item &&
                !!this.item.contents[this.lang] &&
                this.item.contents[this.lang].status == 1
            ) {
                return {
                    item: {
                        contents: this.$contentTemplate({
                            title: {required},
                            description: {required},
                            excerpt: {required},
                            google: {
                                description: {required},
                                keywords: {required},
                                title: {required}
                            },
                            slug: {required},
                            social: {description: {required}, title: {required}}
                        })
                    }
                };
            }
            return {
                item: {
                    contents: this.$contentTemplate({
                        title: {required},
                        description: "",
                        excerpt: "",
                        google: {description: "", keywords: "", title: ""},
                        slug: "",
                        social: {description: "", title: ""},
                        subtitle: ""
                    })
                }
            };
        },
        data() {
            return {
                needSave: false,
                hasErrors: false,
                loading: true,
                item: null,
                lang: "it",
            };
        },
        computed: {
            isValid: function () {
              if (!this.item.canSave) {
                if (this.item.contents[this.lang].status == 1) {
                  return false
                } else {
                  return true
                }
              }
              return true
            },
            template: function () {
                const userId = Number(this.$api.user.current().id);
                return {
                    id: "new",
                    contents: this.$contentTemplate({
                        title: "",
                        slug: "",
                        subtitle: "",
                        description: "",
                        google: {description: "", keywords: "", title: ""},
                        social: {description: "", title: ""},
                        tags: [],
                        permalink: "",
                        author: userId,
                        status: 2,
                    }),
                    location: {},
                    attachments: [],
                    theme: [],
                    destinazione: [],
                    comune: [],
                    canSave: false,
                    created_at: "",
                    portal: [
                                {
                                    'id': 1,
                                    'active': 0
                                    },
                                    {
                                    'id': 2,
                                    'active': 0
                                    },
                                    {
                                    'id': 3,
                                    'active': 0
                                    }
                            ]
                };
            }
        },
        methods: {
            save: _.debounce(function () {

                this.$v.$touch();
                if (!this.$v.item.contents[this.lang].$anyError) {
                    let self = this;
                    let url = `${self.$url}interessi/${self.item.id}`;
                    let method = "put";

                    if (this.item.id == "new") {
                        method = 'post';
                        url = `${self.$url}interessi`;
                    }

                    this.item.lang = this.lang;
                    this.loading = true;

                    axios({
                        method: method,
                        url: url,
                        headers: this.$config.axiosHeaders(),
                        data: this.item
                    })
                        .then(function (response) {
                            self.loading = false;
                            self.$notifyVue(
                                "top",
                                "center",
                                response.data.status,
                                response.data.data.message,
                                "ti-check"
                            );
                            self.$refs.sbox.update();
                            if (response.data.data.id !== self.item.id) {
                                self.item.id = response.data.data.id;
                                self.$router.push({params: {id: self.item.id}})
                            }
                            self.item.contents[self.lang].permalink = response.data.data.permalink + '?preview=1';
                        })
                        .catch(function (error) {
                            self.loading = false;
                            self.hasErrors = true;
                            self.$processError(error);

                        });

                    this.needSave = false;
                }


            }, 300),
            loadData: function () {
                let self = this;
                self.item = null;

                if (this.item && this.id == this.item.id) {
                    console.log("Ricaricamento della stessa pagina");
                    return;
                }

                if (this.id == "new") {
                    self.item = JSON.parse(JSON.stringify(self.template));
                    self.needSave = false;
                    self.loading = false;
                    return;
                }

                self.loading = true;

                axios({
                    method: "get",
                    url: self.$url + self.path + "/" + self.id,
                    headers: this.$config.axiosHeaders(),
                    params: {
                        id: this.id
                    }
                }).then(function (response) {
                    self.item = _.merge(JSON.parse( JSON.stringify(self.template) ), response.data.data);
                    self.loading = false;
                    self.needSave = false;
                }).catch(function (error) {
                    self.loading = false;
                    self.hasErrors = true;
                    self.$processError(error);

                });
            }
        },
        mounted: function () {
            this.loadData();
        },
        watch: {
            item: {
                handler(val) {
                    this.needSave = true;
                },
                deep: true
            },
            id: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData();
                }
            }
        },
    };
</script>


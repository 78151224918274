<template>
    <div class="editor-page">
        <loader v-if="item==null" :show="item==null" :width="40" :height="40"></loader>
        <top-bar
                v-if="item!=null"
                :title="id=='new' ? 'Inserimento Tag' : 'Modifica Tag'"
                :action="`Salva`"
                :actionIsValid="isValid"
                @onAction="save"
        ></top-bar>
        <form :class="['form', 'row']" novalidate v-if="item!=null">
            <div class="col-md-9">
                <card title="Tags" :collapse="false">
                    <content>
                        <textbox
                                title="Titolo"
                                :v="$v.item.name"
                                v-model="item.name"
                        />
                    </content>
                </card>
            </div>
            <div class="col-md-3"><!-- SIDEBAR DX -->

            </div>
        </form>
    </div>
</template>

<script>
    import axios from "axios";

    import Textbox from "../../components/altrama/form/Textbox";
    import TopBar from "../../components/altrama/TopBarFlat";

    import {validationMixin} from "vuelidate";
    import {required,url} from "vuelidate/lib/validators";

    export default {
        components: {
            TopBar,
            Textbox,
        },
        props: ["id"],
        mixins: [validationMixin],
        validations() {
            return {
                item: {
                    name: {required},
                }
            };
        },
        data() {
            return {
                needSave: false,
                hasErrors: false,
                item: null,
                lang: "it"
            };
        },
        computed: {
            isValid: function () {
                return true;
            },
        },
        methods: {
            template: function () {
                return {
                    id: "new",
                    name: ""
                };
            },
            save() {
                this.$v.$touch();
                if (!this.$v.item.name.$anyError) {
                    let self = this;
                    var method = "put";
                    let url = `${self.$url}operations/tags/${self.id}`;

                    if (this.item.id == "new") {
                        var method = "post";
                        url = `${self.$url}operations/tags`;
                    }

                    this.item.lang = this.lang;
                    axios({
                        method: method,
                        url: url,
                        params: {
                            name: self.name,
                        },
                        headers: this.$config.axiosHeaders(),
                        data: this.item
                    })
                        .then(function (response) {
                            console.log('r')
                            console.log(response.data.data);
                            self.$notifyVue(
                                "top",
                                "center",
                                response.data.status,
                                response.data.data.message,
                                "ti-check"
                            );
                            self.item.id = response.data.id;
                        })
                        .catch(function (error) {
                            self.hasErrors = true;
                            self.$processError(error);
                            
                        });

                    this.needSave = false;
                }
            },
            loadData: function () {
                let self = this;

                if (this.id == "new") {
                    self.item = Object.assign({}, self.template());
                    self.needSave = false;
                    return;
                }
                axios({
                    method: "get",
                    url: `${self.$url}operations/tags/${self.id}`,
                    headers: this.$config.axiosHeaders()
                })
                    .then(function (response) {
                        self.item = response.data;
                        self.needSave = false;
                    })
                    .catch(function (error) {
                        self.hasErrors = true;
                        self.$processError(error);
                        
                    });
            }
        },
        mounted: function () {
            this.loadData();
        },
        watch: {
            id: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData();
                }
            }
        }
    };
</script>
<template>
    <div class="editor-page iat">
        <loader v-if="item==null || loading" :show="item==null" :width="40" :height="40"></loader>
        <top-bar
                v-if="item!=null"
                :title="id=='new' ? 'Inserimento OpenData' : 'Modifica OpenData'"
                preview=""
                :action="`Salva`"
                :actionIsValid="isValid"
                :loading="loading || item==null"
                @lang="(l) => lang = l"
                @onAction="save"
        ></top-bar>

        <form class="form row" v-if="item!=null">
            <div class="col-md-9">
                <card title="Informazioni di base" :collapse="false">
                    <content>
                        <div class="form-group">
                            <label>Titolo</label>
                            <input
                                    type="text"
                                    required="true"
                                    class="input form-control"
                                    aria-required="true"
                                    aria-invalid="false"
                                    v-model="item.contents[lang].title"
                            />
                        </div><!-- titolo -->
                        <hr/>
                        <div class="form-group">
                            <label>Descrizione</label>
                            <rich-editor
                                    aria-required="false"
                                    aria-invalid="false"
                                    v-model="item.contents[lang].description"
                            />
                        </div><!-- descrizione -->
                        <div class="form-group">
                            <label>Tags</label>
                            <tag-manager
                                    aria-required="false"
                                    aria-invalid="false"
                                    v-model="item.contents[lang].tags"
                                    :canAddTags="canAddTags"
                                    :language="lang"
                            />
                        </div><!-- TAG -->
                    </content>
                    <footer></footer>
                </card>
                <card title="Elenco File" :collapse="false">
                    <list-file-component v-model="item.attachments" fileExtensionAccepted=".csv,.xml,.json"></list-file-component>
                </card>
            </div>
            <div class="col-md-3"><!-- SIDEBAR DX -->
                <card title="Impostazioni">
                   <statuses-box ref="sbox"  :lang="lang" :entity="item.id" v-model="item.contents[lang].status" title=false></statuses-box><!-- stato -->
                    <author-box v-model="item.contents[lang].author"></author-box><!-- autore -->
                    <date-picker v-model="item.contents[lang].pub_date"></date-picker><!-- data pubblicazione -->
<!--                    <city-combo v-model="item.comune" :province_id=null></city-combo>&lt;!&ndash; comune &ndash;&gt;-->
                </card><!-- impostazioni -->

                <card title="Altro" :collapse="false">
                    <fieldset>
                        <h5>Categoria</h5>
                        <category-combo :mainCategory="5" v-model="item.category"></category-combo>
                    </fieldset><!-- destinazione -->
                </card><!-- altro -->
            </div>
        </form>
    </div>
</template>

<script>
    import axios from "axios";
    import RichEditor from "../../components/altrama/RichEditor";
    import TagManager from "../../components/altrama/TagManager";
    import CategoryCombo from "../../components/altrama/CategoryCombo";
    import AuthorBox from "../../components/altrama/AuthorBox";
    import StatusesBox from "../../components/altrama/StatusesBox";
    import DatePicker from "../../components/altrama/DatePicker";
    import AttachmentsBox from "../../components/altrama/AttachmentsBox";
    import TopBar from "../../components/altrama/TopBar";
    import CityCombo from "../../components/altrama/CityCombo";
    // import ListFileComponent from "../../components/Inputs/listFilesComponent"
    import ListFileComponent from "../../components/altrama/DownloadEntityComponent"

    export default {
        components: {
            RichEditor,
            TagManager,
            CategoryCombo,
            AuthorBox,
            StatusesBox,
            DatePicker,
            AttachmentsBox,
            TopBar,
            CityCombo,
            ListFileComponent
        },
        props: ["id", "path"],
        data() {
            return {
                canAddTags: false,
                needSave: false,
                hasErrors: false,
                loading: true,
                item: null,
                lang: "it"
            };
        },
        computed: {
          isValid: function () {
            if (!this.item.canSave) {
              if (this.item.contents[this.lang].status == 1) {
                return false
              } else {
                return true
              }
            }
            return true
          },
            template: function () {
                const currentUserId = Number(this.$api.user.current().id);
                return {
                    id: "new",
                    contents: this.$contentTemplate({
                        description: "",
                        excerpt: "",
                        google: {description: "", keywords: "", title: ""},
                        social: {description: "", title: ""},
                        subtitle: "",
                        title: "",
                        tags: [],
                        category:[],
                        permalink: "",
                        author: currentUserId,
                        status: 2,
                        pub_date: ""
                    }),
                    attachments: [],
                    destinazione: [],
                    comune: null,
                    created_at: "",
                    updated_at: "",
                };
            }
        },
        methods: {
            save() {
                let self = this;
                let url = `${self.$url}opendata/${self.id}`;
                let method = "put";

                if (this.item.id == "new") {
                    method = 'post';
                    url = `${self.$url}opendata`;
                }

                this.item.lang = this.lang;
                this.loading = true;

                axios({
                    method: method,
                    url: url,
                    headers: this.$config.axiosHeaders(),
                    data: this.item
                })
                    .then(function (response) {
                        self.loading = false;
                        self.$notifyVue(
                            "top",
                            "center",
                            response.data.status,
                            response.data.data.message,
                            "ti-check"
                        );
self.$refs.sbox.update();
                        if (response.data.data.id !== self.item.id) {
                            self.item.id = response.data.data.id;
                            self.$router.push({params: {id: self.item.id}})
                        }
                    })
                    .catch(function (error) {
                        self.loading = false;
                        self.hasErrors = true;
                        self.$processError(error);
                        
                    });
                this.needSave = false;
            },
            loadData: function () {
                let self = this;
                self.item = null;

                if (this.id == "new") {
                    self.item = Object.assign({}, self.template);
                    self.needSave = false;
                    self.loading = false;
                    return;
                }

                self.loading = true;


                axios({
                    method: "get",
                    url: self.$url + self.path + "/" + self.id,
                    headers: this.$config.axiosHeaders(),
                    params: {
                        id: this.id
                    }
                }).then(function (response) {
                    self.item = Object.assign({}, self.template);
                    self.item = Object.assign(self.item, response.data.data);
                    self.needSave = false;
                }).finally(
                    () => self.loading = false
                );

            }
        },

       mounted: async function () {
    this.canAddTags = await this.$api.user.can('view-tag');
    this.loadData();
  },

        watch: {
            item: {
                handler(val) {
                    this.needSave = true;
                },
                deep: true
            },
            id: {
                handler(val, oldVal) {
                    if(oldVal!=val && val=="new")
                        location.reload();
                },
                deep: true
            }
        },
    };
</script>

<style>
    .custom_tag {
        display: inline-block;
        padding: 3px 12px;
        background: #b7bce3;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 10px;
        cursor: pointer;
    }

    .custom_remove {
        padding: 0;
        font-size: 10px;
        margin-left: 5px;
    }
</style>

<template>
  <div class="editor-page news">
    <loader v-if="item==null || loading" :show="item==null" :width="40" :height="40"></loader>
    <top-bar
      v-if="item!=null"
      :title="id=='new' ? 'Inserimento News' : 'Modifica News'"
      preview=""
      :action="`Salva`"
      :actionIsValid="isValid"
      :loading="loading || item==null"
      @lang="(l) => { lang = l; $v.$touch(); }"
      @onAction="save"
    ></top-bar>
    <div v-if="item && item.draft > 0">
      <div class="alert alert-info alert-with-icon">
        <button type="button" aria-hidden="true" class="close">×</button>
        <span data-notify="icon" class="ti-bell"></span>
        <span data-notify="message">Scheda BOZZA di Revisione</span>
      </div>
    </div>
    <form :class="['form', 'row']" novalidate v-if="item!=null">
      <div class="col-md-9">
        <card title="Informazioni di base" :collapse="false">
          <content>
            <textbox
              title="Titolo"
              :v="$v.item.contents[ lang ].title"
              v-model="item.contents[ lang ].title"
            />
            <textbox
              title="Slug"
              :v="$v.item.contents[ lang ].slug"
              v-model="item.contents[ lang ].slug"
            />
            <textbox title="Sottotitolo" v-model="item.contents[ lang ].subtitle"/>
            <a-textarea
              title="Introduzione"
              :v="$v.item.contents[ lang ].excerpt"
              v-model="item.contents[ lang ].excerpt"
            />
            <hr/>
            <fieldset>
              <h5>SEO</h5>
              <textbox
                title="Keywords"
                :v="$v.item.contents[ lang ].google.keywords"
                v-model="item.contents[ lang ].google.keywords"
              />
              <textbox
                title="Title"
                :v="$v.item.contents[ lang ].google.title"
                v-model="item.contents[ lang ].google.title"
              />
              <a-textarea
                title="Meta Descrizione"
                :maxLength="170"
                :v="$v.item.contents[ lang ].google.description"
                v-model="item.contents[ lang ].google.description"
              />
            </fieldset>
            <fieldset>
              <h5>Social</h5>
              <textbox
                title="Title"
                :v="$v.item.contents[ lang ].social.title"
                v-model="item.contents[ lang ].social.title"
              />
              <a-textarea
                title="Meta Descrizione"
                :v="$v.item.contents[ lang ].social.description"
                v-model="item.contents[ lang ].social.description"
              />
            </fieldset>
            <hr/>
            <div class="form-group">
              <label>Descrizione</label>
              <rich-editor
                :v="$v.item.contents[ lang ].description"
                v-model="item.contents[ lang ].description"
              />
            </div>
            <div class="form-group">
              <label>Tags</label>
              <tag-manager
                aria-required="false"
                aria-invalid="false"
                v-model="item.contents[ lang ].tags"
                :canAddTags="canAddTags"
                :language="lang"
              />
            </div>
          </content>
          <footer></footer>
        </card>
        <card title="Immagini" :collapse="false">
          <attachments-box v-model="item.attachments"></attachments-box>
        </card>
      </div>
      <div class="col-md-3"><!-- SIDEBAR DX -->
        <card title="Impostazioni">
         <statuses-box ref="sbox"  :lang="lang" :entity="item.id" v-model="item.contents[ lang ].status" title=false></statuses-box><!-- stato -->
          <author-box v-model="item.contents[lang].author"></author-box><!-- autore -->
          <date-picker v-model="item.contents[lang].pub_date"></date-picker><!-- data pubblicazione -->
          <city-combo-multiselect v-model="item.comune" city-name="" provincia=""></city-combo-multiselect><!-- comune -->
        </card><!-- impostazioni -->
        <card title="Categorie" :collapse="false">
          <fieldset>
            <h5>Destinazione</h5>
            <category-combo :mainCategory="2" v-model="item.destinazione"></category-combo>
          </fieldset><!-- destinazione -->
          <hr/>
          <fieldset>
            <h5>Categoria</h5>
            <category-combo :mainCategory="1" v-model="item.category"></category-combo>
          </fieldset><!-- categoria -->
          <hr/>
          <fieldset>
            <h5>Target</h5>
            <category-combo :mainCategory="6" v-model="item.target"></category-combo>
          </fieldset><!-- target -->
          <hr/>
          <fieldset>
            <h5>Interesse</h5>
            <category-combo :mainCategory="5" v-model="item.theme"></category-combo>
          </fieldset><!-- interesse -->
          <hr/>
        </card><!-- categorie -->
      </div>
    </form>
  </div>
</template>
<script>
  import axios from "axios";

  import Textbox from "../../components/altrama/form/Textbox";
  import ATextarea from "../../components/altrama/form/ATextarea";
  import RichEditor from "../../components/altrama/RichEditor";
  import TagManager from "../../components/altrama/TagManager";
  import CategoryCombo from "../../components/altrama/CategoryCombo";
  import ContactsEditor from "../../components/altrama/ContactsEditor";
  import AuthorBox from "../../components/altrama/AuthorBox";
  import StatusesBox from "../../components/altrama/StatusesBox";
  import DatePicker from "../../components/altrama/DatePicker";
  import AdvancedDatePicker from "../../components/altrama/AdvancedDatePicker";
  import AdvancedTimePicker from "../../components/altrama/AdvancedTimePicker";
  import TicketBox from "../../components/altrama/TicketBox";
  import TappeBox from "../../components/altrama/TappeBox";
  import AttachmentsBox from "../../components/altrama/AttachmentsBox";
  import TopBar from "../../components/altrama/TopBar";
  import CityCombo from "../../components/altrama/CityCombo";
  import CityComboMultiselect from "../../components/altrama/CityComboMultiselect";
  import PortalBox from "../../components/altrama/PortalBox";

  import {validationMixin} from "vuelidate";
  import {required} from "vuelidate/lib/validators";

  export default {
    components: {
      RichEditor,
      TagManager,
      CategoryCombo,
      ContactsEditor,
      AuthorBox,
      StatusesBox,
      DatePicker,
      AdvancedDatePicker,
      AdvancedTimePicker,
      AttachmentsBox,
      TappeBox,
      TicketBox,
      TopBar,
      Textbox,
      ATextarea,
      CityCombo,
      CityComboMultiselect,
      PortalBox
    },
    props: ["id"],
    mixins: [validationMixin],
    validations() {
      if (
        !!this.item &&
        !!this.item.contents[this.lang] &&
        this.item.contents[this.lang].status == 1
      ) {
        return {
          item: {
            contents: this.$contentTemplate({
              title: {required},
              description: {required},
              excerpt: {required},
              google: {
                description: {required},
                keywords: {required},
                title: {required}
              },
              slug: {required},
              social: {description: {required}, title: {required}}
            })
          }
        };
      }
      return {
        item: {
          contents: this.$contentTemplate({
            title: {required},
            description: "",
            excerpt: "",
            google: {description: "", keywords: "", title: ""},
            slug: "",
            social: {description: "", title: ""},
            subtitle: ""
          })
        }
      };
    },
    data() {
      return {
        canAddTags: false,
        needSave: false,
        hasErrors: false,
        loading: true,
        item: null,
        lang: "it"
      };
    },
    computed: {
      isValid: function () {
        if (!this.item.canSave) {
          if (this.item.contents[this.lang].status == 1) {
            return false
          } else {
            return true
          }
        }
        return true
      },
      template: function () {
        const userId = Number(this.$api.user.current().id);
        return {
          id: "new",
          contents: this.$contentTemplate({
            description: "",
            excerpt: "",
            google: {description: "", keywords: "", title: ""},
            slug: "",
            social: {description: "", title: ""},
            subtitle: "",
            title: "",
            tags: [],
            author: userId,
            status: 2,
            permalink: "",
            pub_date: this.$moment().format("YYYY-MM-DD HH:mm:ss")
          }),
          locations: [],
          category: [],
          attachments: [],
          target: [],
          theme: [],
          destinazione: [],
          portal: [
            {
              'id': 1,
              'active': false
            },
            {
              'id': 2,
              'active': false
            },
            {
              'id': 3,
              'active': false
            }
          ],
          comune: [],
          canSave: false,
          created_at: "",
          updated_at: ""
        };
      }
    },
    methods: {
      save() {
        this.$v.$touch();
        if (!this.$v.item.contents[this.lang].$anyError) {
          let self = this;
          let url = `${self.$url}news/${self.id}`;
          let method = "put";

          if (this.item.id == "new") {
            method = 'post';
            url = `${self.$url}news`;
          }

          this.item.lang = this.lang;
          this.loading = true;

          axios({
            method: method,
            url: url,
            headers: this.$config.axiosHeaders(),
            data: this.item
          })
            .then(function (response) {
              self.loading = false;
              self.$notifyVue(
                "top",
                "center",
                response.data.status,
                response.data.data.message,
                "ti-check"
              );
              if (response.data.data.id !== self.item.id) {
                self.item.id = response.data.data.id;
                self.$router.push({params: {id: self.item.id}})
                
              }
              self.$refs.sbox.update();
            })
            .catch(function (error) {
              self.loading = false;
              self.hasErrors = true;
              self.$processError(error);
              
            });

          this.needSave = false;
        }
      },
      loadData: function () {
        let self = this;
        self.item = null;

        if (this.id == "new") {
          self.item = JSON.parse(JSON.stringify(self.template));
          self.needSave = false;
          self.loading = false;
          return;
        }

        self.loading = true;

        axios({
          method: "get",
          url: `${self.$url}news/${self.id}`,
          headers: this.$config.axiosHeaders()
        })
          .then(function (response) {
            self.item = _.merge(JSON.parse( JSON.stringify(self.template) ), response.data.data);
            self.loading = false;
            self.needSave = false;
          })
          .catch(function (error) {
            self.loading = false;
            self.hasErrors = true;
            self.$processError(error);
            
          });
      }
    },
    mounted: async function () {
    this.canAddTags = await this.$api.user.can('view-tag');
    this.loadData();
  },
    watch: {
      id: {
        handler(val, oldVal) {
          this.loadData();
        },
        deep: true
      }
    }
  };
</script>
